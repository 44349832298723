import { Brand } from "../configurations";
import { parse } from "url";
import { BrandUtils } from "./brandUtils";
import { NativeUtils } from "./nativeUtils";
import { PageContextUtils } from "../utils/pageContextUtils";
import { RegulationTypeIds } from "../constants";

export class UrlUtils {
  static getLinkWithQuery = (link, query) => {
    if (!link) return undefined;
    if (!query || !(query instanceof Object) || !Object.keys(query).length)
      return link;

    const parsedURL = parse(link, true);
    if (parsedURL) {
      parsedURL.query = { ...parsedURL.query, ...query };

      return parsedURL.format();
    }

    return link;
  };

  static getLink = (link, ignoreExistingSearch = false) => {
    if (!link) return "javascript:void(0);";
    if (UrlUtils.findIfIsAbsoluteUrl(link)) return link;

    const url = new URL(link, window.location.origin);

    if (!ignoreExistingSearch) {
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.forEach((value, key) => {
        if (!url.searchParams.has(key)) {
          url.searchParams.set(key, value);
        }
      });
    }

    url.pathname = url.pathname || "/";

    return url.toString().replace(/^(?:\/\/|[^/]+)*\//, "/");
  };

  static getRelativeURL = url => {
    if (!url) return url;

    return url.replace(/.*\/\/[^/]*/, "");
  };

  static findIfIsAbsoluteUrl = link => {
    if (!link) return false;
    const r = new RegExp("^(?:[a-z]+:)?//", "i");

    return r.test(link);
  };

  static getNativeLink = link => {
    if (!link) return undefined;
    //const { PF } = window;
    const parsedUrl = parse(link, true);

    //if (!PF) return link;

    parsedUrl.search = "";
    parsedUrl.query = {
      ...parsedUrl.query
    };

    if (NativeUtils.findIfIsNative()) parsedUrl.query.NativeExternal = true;

    return parsedUrl.format();
  };

  static getURLWithSCut = (link, nativeExternalLink = false) => {
    if (!link) return undefined;

    let sCut = window["sCut"];
    if (sCut !== null && typeof sCut === "object") sCut = sCut.queryString();

    if (!sCut || !(typeof sCut === "string"))
      return nativeExternalLink ? UrlUtils.getNativeLink(link) : link;

    const parsedUrl = parse(link, true);
    const parsedSCut = parse(`/?${sCut}`, true);

    parsedUrl.search = "";
    parsedUrl.query = {
      ...parsedUrl.query,
      ...parsedSCut.query
    };

    const formattedLink = parsedUrl.format();

    return nativeExternalLink
      ? UrlUtils.getNativeLink(formattedLink)
      : formattedLink;
  };

  static getURLWithLang = url => {
    const { brandId } = PageContextUtils.getBrandData();
    const { contentLangId, defaultLang, langId } =
      PageContextUtils.getLocalizationData();

    if (BrandUtils.isPokerClient() || BrandUtils.isMrGreen()) {
      return url;
    }

    if (!url || typeof url !== "string" || brandId === Brand.Sport_CA)
      return url;
    if (langId === defaultLang && langId === contentLangId) return url;

    if (
      url.indexOf(`/${defaultLang}/`) >= 0 ||
      url.indexOf(`/${langId}/`) >= 0 ||
      url.indexOf(`/${contentLangId}/`) >= 0
    )
      return url;

    return `/${contentLangId}${url}`;
  };

  static getSEOUrlWithoutLang = url => {
    let returnedUrl = this.getURLWithoutLang(url || "");

    const { clientBaseUrl } = PageContextUtils.getSiteData();
    if (
      clientBaseUrl &&
      clientBaseUrl !== "/" &&
      !returnedUrl?.startsWith(`${clientBaseUrl}/`)
    ) {
      returnedUrl = `${clientBaseUrl}${returnedUrl}`;
    }

    return returnedUrl;
  };

  static getSEOUrl = url => {
    return this.getURLWithLang(UrlUtils.getSEOUrlWithoutLang(url));
  };

  static getURLWithoutLang = url => {
    if (!url || typeof url !== "string") return url;

    const { contentLangId } = PageContextUtils.getLocalizationData();

    return url.replace(`/${contentLangId}/`, "/");
  };

  static getCurrentRelativeUrl = () => {
    return window.location.href.replace(window.location.origin, "");
  };

  static getURLWithoutHostname = url => {
    if (!url) return url;
    return url.replace(/.*\/\/[^/]*/, "");
  };

  static getURLWithOrigin = (url, withOrigin) => {
    if (!url || !withOrigin) return url;

    var relativeUrl = UrlUtils.getURLWithoutHostname(url);

    return withOrigin + relativeUrl;
  };

  static getCurrentOriginURLWithLang = () => {
    const { brandId } = PageContextUtils.getBrandData();
    var { mainPage } = PageContextUtils.getSiteData();
    const { contentLangId, defaultLang, langId } =
      PageContextUtils.getLocalizationData();

    const url = window.location.origin;
    mainPage = (mainPage ?? "").endsWith("/") ? mainPage : `${mainPage}/`;

    if (brandId === Brand.Sport_CA) {
      return `${url}${mainPage}`;
    }

    if (langId === defaultLang && langId === contentLangId)
      return `${url}${mainPage}`;

    const originUrl = BrandUtils.isPokerClient()
      ? `${url}${mainPage}`
      : `${url}/${contentLangId}${mainPage}`;
    return originUrl;
  };

  static getQueryStringParameterByName = name => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });

    return params[name];
  };

  static isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }

  static getPokerDownloadSmartUrl() {
    const { brandId } = PageContextUtils.getBrandData();
    const { regulationTypeId } = PageContextUtils.getRegulationData();
    const isUK = regulationTypeId === RegulationTypeIds.UK;
    switch (brandId) {
      case Brand.Sport_Scasino: {
        return isUK
          ? "https://888pic.onelink.me/i3ZS/whzhgfd7"
          : "https://888pissa.onelink.me/3vpG/1d9w0xah";
      }
      case Brand.Sport_Spoker: {
        return "https://poker1com.onelink.me/NSrO/gxcdo59v";
      }
      case Brand.Sport_IT: {
        return "https://pokerit.onelink.me/Neok/native";
      }
      default: {
        return isUK
          ? "https://888pis.onelink.me/XSR2/uw4bwhui"
          : "https://888pissa.onelink.me/aDZq/a37zhll6";
      }
    }
  }

  static getLanguageSwitcherUrl = (url, withOrigin) => {
    if (!url || !withOrigin) return url;

    if (BrandUtils.isPokerClient() || BrandUtils.isMrGreen()) {
      return UrlUtils.getSubdomainLanguageSwitcherUrl(url, withOrigin);
    }

    var relativeUrl = UrlUtils.getURLWithoutHostname(url);

    return withOrigin + relativeUrl;
  };

  static getSubdomainExcludingLastPart = url => {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    const parts = hostname.split(".");
    const subdomain = parts[0];
    const subdomainParts = subdomain.split("-");
    subdomainParts.pop();

    return subdomainParts.join("-") || "";
  };

  static getLastPartOfSubdomain = url => {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    const parts = hostname.split(".");
    const subdomain = parts[0];
    const subdomainParts = subdomain.split("-");

    return subdomainParts[subdomainParts.length - 1];
  };

  static getDomainWithoutSubdomain = url => {
    const parsedUrl = new URL(url);
    const hostname = parsedUrl.hostname;
    const parts = hostname.split(".");
    if (parts.length > 1) {
      return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }

    return hostname;
  };

  static getSubdomainLanguageSwitcherUrl = (url, currentUrlOrigin) => {
    const nextURL = new URL(url, currentUrlOrigin);
    const currentURL = currentUrlOrigin;
    const lastPartSubdomain = UrlUtils.getLastPartOfSubdomain(url);
    const firstPartSubdomain =
      UrlUtils.getSubdomainExcludingLastPart(currentURL);

    const domainUrl = UrlUtils.getDomainWithoutSubdomain(currentURL);

    const newURL = new URL(
      `https://${firstPartSubdomain ? `${firstPartSubdomain}-` : ""}${lastPartSubdomain}.${domainUrl}/`
    );

    newURL.pathname = nextURL.pathname;
    newURL.search = nextURL.search;

    return newURL.toString();
  };
}
